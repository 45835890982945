import React from "react";
import Search from "./Search/Search";
import Detail from "./Detail/Detail";
import Info from "./Info/Info";
import Loading from "./Loading";
import Checkout from "./Checkout/Checkout";
import { useExploreState } from "../../context/ExploreContext";

const ActualSwitcher = (props) => {
  const { explore } = useExploreState();

  switch (explore.actual) {
    case "SEARCH":
      return <Search {...props} />;
    case "INFO":
      return <Info {...props} />;
    case "DETAIL":
      return <Detail {...props} />;
    case "CHECKOUT":
      return <Checkout {...props} />;
    case "LOADING":
      return <Loading {...props} />;
    default:
      return <Search {...props} />;
  }
};

export default ActualSwitcher;
