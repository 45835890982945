import React, { useState } from 'react'
import styled from 'styled-components';
import { FaSearch, FaTimes } from "react-icons/fa";
import InputContainer from './InputContainer'
import InputWithIcon from './InputWithIcon'

const SelectInput = ({ marginTop, options, onSelect, title, placeholder, value, rightNotRounded, leftNotRounded, maxWidth }) => {
    const [searchText, setSearchText] = useState('');
    const [active, setActive] = useState(true);
    const [filteredOptions, setFilteredOptions] = useState(options);

    const filterOptions = (searchString) => {
        setSearchText(searchString);
        let result = options.filter(option => option.value.toLowerCase().includes(searchString.toLowerCase()));
        setFilteredOptions(result);
    }

    const select = (key) => {
        onSelect(key);
    }
    return (
        <div style={{ maxWidth: maxWidth }}>
            <InputContainer title={title} marginTop={marginTop} rightNotRounded={rightNotRounded} leftNotRounded={leftNotRounded}>
                <div onClick={() => setActive(true)}>
                    <InputWithIcon
                        type="search"
                        placeholder={placeholder}
                        value={searchText}
                        onChange={event => filterOptions(event.currentTarget.value)}
                        Icon={searchText ? FaTimes : FaSearch}
                        onIconClick={searchText ? () => { setSearchText('');; setFilteredOptions(options) } : undefined}
                    />
                    {active &&
                        <React.Fragment>
                            {filteredOptions.length > 0 && <Line />}
                            <ItemsWrapper>
                                {filteredOptions.map((option) => (
                                    <Item key={option.key} onClick={() => select(option.key)}>
                                        <Input
                                            type="radio"
                                            value={option.key}
                                            checked={option.key === value}
                                            readOnly
                                        />
                                        <Label>{option.value}</Label>
                                    </Item>
                                ))
                                }
                            </ItemsWrapper>
                        </React.Fragment>
                    }
                </div>
            </InputContainer>
        </div>
    );
}

const ItemsWrapper = styled.div`
    max-height: 180px;
    overflow-y: auto;
`

const Line = styled.hr`
    margin: 0px;
    height: 1px;
    background-color: #B2C3CC;
    border-width: 0px;
`

const Item = styled.div`
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    &:hover{
        background-color: #F8F8F8
    }
`

const Input = styled.input`
    margin: 0px;
    margin-right: 20px;
    cursor: pointer;
    height: 15px;
`

const Label = styled.label`
    font-size: 15px;
    cursor: pointer;
`

export default SelectInput;