import React, { useState } from "react";
import styled from "styled-components";
import ButtonContainer from "../../styled-components/ButtonContainer";
import { FaShoppingCart, FaAngleLeft } from "react-icons/fa";
import {
  useExploreState,
  useExploreDispatch,
} from "../../../context/ExploreContext";
import Sidebar from "../Sidebar";
import Button from "../../Inputs/Button";
import Title from "../Title";
import Checkbox from "../../Inputs/Checkbox";
import ScaleLoader from "react-spinners/ScaleLoader";
import { gql, useMutation } from "@apollo/client";

const CREATE_CHECKOUT_SESSION = gql`
  mutation Mutation(
    $countryCode: String!
    $licensePlate: String!
    $passRateId: Int!
    $startDate: DateTime!
    $zoneIds: [Int!]!
  ) {
    createCheckoutSession(
      countryCode: $countryCode
      licensePlate: $licensePlate
      passRateId: $passRateId
      startDate: $startDate
      zoneIds: $zoneIds
    ) {
      sessionUrl
    }
  }
`;

const Info = () => {
  const [createCheckoutSession] = useMutation(CREATE_CHECKOUT_SESSION);
  const [agb, setAgb] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [loading, setLoading] = useState(false);

  const { explore, pass } = useExploreState();
  const dispatchExplore = useExploreDispatch();

  const onBackClick = () => {
    dispatchExplore({ type: "setCheckoutToDetail" });
  };

  const buy = () => {
    setLoading(true);
    const finalPass = pass;
    createCheckoutSession({
      variables: {
        countryCode: finalPass.country,
        licensePlate: finalPass.licensePlate,
        passRateId: finalPass.passRateId,
        startDate: finalPass.startDate,
        zoneIds: [
          ...explore.disabledZones,
          ...explore.selectedZones,
          ...explore.requiredZones,
        ],
      },
      onCompleted: async (data) => {
        window.location.href = data.createCheckoutSession.sessionUrl;
      },
    });
  };

  return (
    <Sidebar headline="Kauf abschließen" width="500px">
      <Title>{explore.roadName}</Title>
      <PriceContainer>
        <Price>{pass.price}€</Price>
      </PriceContainer>
      <div style={{ marginBottom: "15px" }}>
        <label>
          <Checkbox
            checked={agb}
            onChange={() => setAgb(!agb)}
            disabled={loading}
          />
          <span style={{ marginLeft: "15px" }}>AGB akzeptieren</span>
        </label>
      </div>
      <label>
        <Checkbox
          checked={privacy}
          onChange={() => setPrivacy(!privacy)}
          disabled={loading}
        />
        <span style={{ marginLeft: "15px" }}>Datenschutz akzeptieren</span>
      </label>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <ScaleLoader
            color="#165E16"
            loading={true}
            height={35}
            width={4}
            radius={2}
            margin={2}
          />
        </div>
      ) : (
        <ButtonContainer>
          <Button
            Icon={FaAngleLeft}
            left={true}
            text="Zurück"
            onClick={onBackClick}
            disabled={loading}
          />
          <Button
            left={false}
            Icon={FaShoppingCart}
            text="Kauf abschließen"
            onClick={() => buy()}
            disabled={!privacy || !agb || loading}
          />
        </ButtonContainer>
      )}
    </Sidebar>
  );
};

const Price = styled.p`
  font-size: 40px;
  color: #447604;
  margin-bottom: 20px;
  margin-top: 0px;
`;

const PriceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default Info;
