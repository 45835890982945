import Button from "../../Inputs/Button";
import ButtonContainer from "../../styled-components/ButtonContainer";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import ScaleLoader from "react-spinners/ScaleLoader";
import addDays from "date-fns/addDays";
import addMonths from "date-fns/addMonths";
import addYears from "date-fns/addYears";
import format from "date-fns/format";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useExploreDispatch,
  useExploreState,
} from "../../../context/ExploreContext";
import { getTitleForType } from "../../../functions/helpers";
import Card from "../Sidebar";
import Title from "../Title";
import SelectInput from "../../Inputs/SelectInput";
import TextReadOnly from "../../Inputs/TextReadOnly";
import TextInput from "../../Inputs/TextInput";
import DateInput from "../../Inputs/DateInput";
import HideableSelectInput from "../../Inputs/HideableSelectInput";
import { gql, useQuery } from "@apollo/client";
import { startOfDay, subMinutes } from "date-fns";

const GET_PASSES = gql`
  query Query($roadId: Int!) {
    passRatesByRoad(roadId: $roadId) {
      id
      type
      name
      startDate
      endDate
    }
  }
`;

const GET_COUNTRIES = gql`
  query Query {
    countries {
      code
      name
    }
  }
`;

const Detail = () => {
  const { explore, pass } = useExploreState();

  //const [passes, setPasses] = useState([]);
  //const [countries, setCountries] = useState([]);

  const [actualPass, setActualPass] = useState(
    explore.detailPresets?.actualPass || 0
  );

  const [startDate, setStartDate] = useState(
    explore.detailPresets?.startDate || startOfDay(new Date())
  );
  const [endDate, setEndDate] = useState(
    explore.detailPresets?.endDate || new Date()
  );
  const [licensePlate, setLicensePlate] = useState(
    explore.detailPresets?.licensePlate || ""
  );
  const [country, setCountry] = useState(explore.detailPresets?.country || "A");

  const [buyDisabled, setBuyDisabled] = useState(
    explore.detailPresets?.buyDisabledFalse ? false : true
  );
  //const [loading, setLoading] = useState(false);

  const dispatchExplore = useExploreDispatch();

  const { data: tempPasses } = useQuery(GET_PASSES, {
    variables: { roadId: explore.roadId },
  });

  let passes = tempPasses?.passRatesByRoad || [];

  const { data: tempCountries } = useQuery(GET_COUNTRIES);

  let countries = tempCountries?.countries || [];

  /*useEffect(() => {
        let tempPasses = [];
        firestore.collection('roads/' + explore.roadId + '/zones/' + explore.zoneId + '/passes').get().then(querySnapshot => {
            querySnapshot.forEach(function (doc) {
                tempPasses.push(doc.data());
            });
            setPasses(tempPasses);
        });
        let tempCountries = [];
        firestore.collection('countries').get().then(querySnapshot => {
            querySnapshot.forEach(function (doc) {
                let tempCountry = doc.data();
                tempCountry.id = doc.id;
                tempCountries.push(tempCountry);
            });
            setCountries(tempCountries);
        });
    }, [explore.roadId, explore.zoneId]);*/

  useEffect(() => {
    if (passes.length > 0) {
      switch (passes[actualPass].type) {
        case "ENDLESS":
          setEndDate("ENDLESS");
          break;
        case "DAY":
          setEndDate(subMinutes(addDays(startDate, 1), 1));
          break;
        case "THREEDAY":
          setEndDate(subMinutes(addDays(startDate, 3), 1));
          break;
        case "WEEK":
          setEndDate(subMinutes(addDays(startDate, 7), 1));
          break;
        case "MONTH":
          setEndDate(subMinutes(addMonths(startDate, 1), 1));
          break;
        case "YEAR":
          setEndDate(subMinutes(addYears(startDate, 1), 1));
          break;
        case "CUSTOM":
          setEndDate(subMinutes(new Date(passes[actualPass].endDate), 1));
          break;
        case "CUSTOM_RANGE":
          setEndDate(
            subMinutes(new Date(passes[actualPass].endDate.seconds * 1000), 1)
          );
          break;
        case "CUSTOM_DURATION":
          setEndDate(
            subMinutes(addDays(startDate, passes[actualPass].duration), 1)
          );
          break;
        default:
          setEndDate(subMinutes(addYears(startDate, 1), 1));
          break;
      }
    }
  }, [startDate, actualPass, passes]);

  const handlePassChange = (passIndex) => {
    setActualPass(passIndex);
  };

  const getFinalPass = () => {
    let finalPass = pass;
    finalPass.startDate = startDate.toDateString();
    finalPass.endDate =
      endDate === "ENDLESS" ? "ENDLESS" : endDate.toDateString();
    finalPass.price = getPrice().price;
    finalPass.passRateId = passes[actualPass].id;
    finalPass.licensePlate = licensePlate;
    finalPass.country = country;
    return finalPass;
  };

  const getFormattedPasses = () => {
    return passes.map((pass, index) => {
      return { key: index, value: getTitleForType(pass) };
    });
  };

  const getFormattedCountries = () => {
    return countries.map((item) => {
      return { key: item.code, value: item.code + " - " + item.name };
    });
  };

  const onBackClick = () => {
    dispatchExplore({ type: "setDetailToInfo" });
  };

  const onNextClick = () => {
    const finalPass = getFinalPass();
    dispatchExplore({
      type: "setPassName",
      data: getTitleForType(passes[actualPass]),
    });
    dispatchExplore({ type: "setPass", data: { pass: finalPass } });
    dispatchExplore({
      type: "setDetailPresets",
      data: {
        actualPass: actualPass,
        startDate: startDate,
        endDate: endDate,
        licensePlate: licensePlate,
        country: country,
        buyDisabledFalse: true,
      },
    });
    dispatchExplore({ type: "setDetailToCheckout" });
  };

  const getPrice = () => {
    let allZones = [
      ...explore.selectedZones,
      ...explore.disabledZones,
      ...explore.requiredZones,
    ];
    allZones = [...new Set(allZones)];
    let price = 0;
    let priceText = "";
    if (allZones.length > 0) {
      priceText = "(" + price + "€ Hauptweg \n";
      for (const zoneId of allZones) {
        const zone = explore.zones.find((zone) => zone.id === zoneId);
        const zonePrice = zone.passRates.find(
          (passRate) => passRate.passRate.id === passes[actualPass].id
        ).price;
        price += zonePrice;
        priceText += ", " + zonePrice + "€ " + zone.name;
      }
      priceText += ")";
    }

    return { price, priceText };
  };

  return (
    <Card headline="Pass konfigurieren">
      {passes.length === 0 || countries.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <ScaleLoader
            color="#165E16"
            loading={true}
            height={35}
            width={4}
            radius={2}
            margin={2}
          />
        </div>
      ) : (
        <React.Fragment>
          <Title>{explore.roadName}</Title>
          <SelectInput
            options={getFormattedPasses()}
            title="Pass auswählen"
            placeholder="Pass suchen oder auswählen"
            value={actualPass}
            onSelect={(index) => handlePassChange(index)}
          />

          {passes[actualPass].type === "CUSTOM" ? (
            <TextReadOnly title="Startdatum" marginTop="20px">
              {format(new Date(passes[actualPass].startDate), "dd.MM.yyyy")}
            </TextReadOnly>
          ) : (
            <DateInput
              title="Startdatum"
              marginTop="20px"
              value={startDate}
              minDate={new Date()}
              onChange={(date) => (date === null ? null : setStartDate(date))}
            />
          )}
          <TextReadOnly title="Enddatum" marginTop="20px">
            {endDate === "ENDLESS"
              ? "Unendlich"
              : format(endDate, "dd.MM.yyyy")}
          </TextReadOnly>
          <React.Fragment>
            <LicensePlateContainer>
              <HideableSelectInput
                title="Land"
                options={getFormattedCountries()}
                onSelect={(key) => setCountry(key)}
                value={country}
                placeholder={"Land auswählen"}
                rightNotRounded={true}
                maxWidth="200px"
              />
              <TextInput
                leftNotRounded={true}
                title="Kennzeichen"
                placeholder="Kennzeichen"
                value={licensePlate}
                onChange={(event) => {
                  let licensePlate = event.target.value.toUpperCase();
                  licensePlate = licensePlate.replace(/[^A-Za-z0-9-]/g, "");
                  setLicensePlate(licensePlate);
                  setBuyDisabled(licensePlate ? false : true);
                }}
              />
            </LicensePlateContainer>
          </React.Fragment>
          <TextReadOnly title="Preis" marginTop="20px" color="#447604">
            {getPrice().price} €
          </TextReadOnly>
          <ButtonContainer>
            <Button
              Icon={FaAngleLeft}
              left={true}
              text="Zurück"
              onClick={onBackClick}
            />
            <Button
              Icon={FaAngleRight}
              left={false}
              text="Weiter"
              onClick={onNextClick}
              disabled={buyDisabled}
            />
          </ButtonContainer>
        </React.Fragment>
      )}
    </Card>
  );
};

const LicensePlateContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
`;

export default Detail;
