import styled from "styled-components";

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
`;

export default ButtonContainer;
