import React from 'react';
import styled from 'styled-components';

const InputWithIcon = ({ type, value, onChange, onClick, placeholder, Icon, onIconClick, color, ...rest }) => {
    return (
        <Container>
            <Input type={type} value={value} onChange={onChange} placeholder={placeholder} onClick={onClick} color={color} {...rest} />
            {Icon && <Icon color="#2C3E50" onClick={onIconClick === 'ON_CLICK' ? onClick : onIconClick} style={{ padding: "10px", cursor: onIconClick ? "pointer" : "auto" }} />}
        </Container>
    );
}

const Input = styled.input`
    background: transparent;
    margin: 0px;
    text-decoration: none;
    outline: none;
    border: none;
    font-family: "Montserrat";
    font-size: 15px;
    width: 100%;
    padding: 10px;
    color: ${props => props.color};
`

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`

export default InputWithIcon;