import React from "react";
import styled from "styled-components";
import Footer from "../Footer/Footer";

const Agb = () => {
  return (
    <Wrapper>
      <InnerWrapper>
        <HeadingWrapper>
          <h1>AGB</h1>
          <Close href="/">X</Close>
        </HeadingWrapper>
        <h2>1. Einleitung und Definitionen</h2>
        <h3>1.1. Anwendung</h3>
        <ul>
          <li>
            Die Allgemeinen Gesch&auml;ftsbedingungen (nachfolgend "AGB")
            definieren die Nutzung der &ouml;sterreichischen Webseite mauto.at
            (im Folgenden: "Plattform") sowie die damit verbundenen
            Dienstleistungen und Anwendungen, die von der Zewas GmbH im Rahmen
            der Nutzung der Webseite mauto.at f&uuml;r Kunden bereitgestellt
            werden.
          </li>
        </ul>
        <h2>2. Geltungsbereich</h2>
        <h3>2.1. Vertragsparteien</h3>
        <ul>
          <li>
            Diese Allgemeinen Gesch&auml;ftsbedingungen (im Folgenden
            &bdquo;AGB&ldquo;) gelten f&uuml;r alle rechtlichen
            Rechtsverh&auml;ltnisse zwischen der Zewas GmbH, Gerbe 525, 6863 Egg
            (im Folgenden "Zewas"), und den Nutzern der Mauto-Services (im
            Folgenden "Nutzer"), die im Zusammenhang mit der Nutzung der
            Wege-Datenbank (im Folgenden "Datenbank") oder sonstigen Diensten
            der Plattform entstehen. Nutzer der Plattform k&ouml;nnen sowohl
            nat&uuml;rliche Personen als auch juristische Personen und
            Personengesellschaften sein.
          </li>
        </ul>
        <h2>3. Vertragsgegenstand</h2>
        <h3>3.1. Leistungen</h3>
        <ul>
          <li>
            In der von Zewas betriebenen Datenbank sind Wege und Wegabschnitte
            gespeichert, die von den jeweiligen Wegbetreibern (im Folgenden
            &bdquo;Wegbetreiber&ldquo;) eingestellt werden. Zewas bietet den
            Nutzern die M&ouml;glichkeit, nach diesen Wegen zu suchen und online
            auf die in der Datenbank gespeicherten Informationen zuzugreifen.
          </li>
          <li>
            &Uuml;ber die Plattform k&ouml;nnen Berechtigungsscheine f&uuml;r
            die Nutzung der beworbenen Wege (im Folgenden
            &bdquo;Bewerbungsscheine&ldquo;) erworben werden, wobei der Nutzer
            als K&auml;ufer auftritt. Zewas fungiert hierbei lediglich als
            technischer Dienstleister und nicht als K&auml;ufer, Verk&auml;ufer
            oder Vertreter der &uuml;ber die Plattform vertriebenen
            Berechtigungsscheine. Die Vertr&auml;ge werden ausschlie&szlig;lich
            zwischen dem Wegbetreiber des jeweiligen Weges (als
            &bdquo;Verk&auml;ufer&ldquo;) und dem Nutzer (als
            &bdquo;K&auml;ufer&ldquo;) abgeschlossen.
          </li>
          <li>
            Zewas beh&auml;lt sich das Recht vor, den Zugriff auf die Inhalte
            der Datenbank nach eigenem Ermessen zu erm&ouml;glichen. Ein
            Anspruch des Nutzers auf die Beibehaltung bestimmter Leistungen oder
            Teile davon besteht nicht. Der Anspruch von Nutzern auf Nutzung der
            Plattform besteht nur im Rahmen des aktuellen Stand der Technik
          </li>
          <li>
            Falls es zu einem unvorhergesehenen Systemausfall kommt, der die
            Nutzung der Plattform beeintr&auml;chtigt, werden die Nutzer
            entsprechend informiert.
          </li>
        </ul>
        <h2>4. Registrierung und Benutzerkonto</h2>
        <h3>4.1. Registrierung</h3>
        <ul>
          <li>
            F&uuml;r die Nutzung bestimmter Dienste der Plattform ist eine
            Registrierung des Nutzers erforderlich. Diese Registrierung steht
            ausschlie&szlig;lich vollj&auml;hrigen und voll
            gesch&auml;ftsf&auml;higen Personen offen.
          </li>
          <li>
            Bei der Registrierung muss der Nutzer Der Nutzer ist verpflichtet,
            die Angaben im Registrierungsformular wahrheitsgem&auml;&szlig; zu
            machen und bei &Auml;nderungen unverz&uuml;glich zu aktualisieren.
            Jeder Nutzer darf sich nur einmal auf der Plattform registrieren.
            Registrierungen sind nicht &uuml;bertragbar und d&uuml;rfen
            ausschlie&szlig;lich vom registrierten Nutzer verwendet werden.
          </li>
          <li>
            Durch die Registrierung entsteht zwischen Zewas und dem Nutzer ein
            Vertrag &uuml;ber die Nutzung der Dienste der Plattform (im
            Folgenden "Nutzungsvertrag").
          </li>
        </ul>
        <h3>4.2. Benutzerkonto</h3>
        <ul>
          <li>
            Die E-Mail-Adresse und das Passwort dienen als Zugangsdaten f&uuml;r
            die Nutzung der registrierungspflichtigen Dienste der Plattform. Der
            Nutzer ist verpflichtet, das Passwort vertraulich zu behandeln und
            nicht an Dritte weiterzugeben. Sollte ein Dritter Kenntnis vom
            Passwort des Nutzers erlangen, muss der Nutzer dies umgehend Zewas
            melden und das Passwort &auml;ndern.
          </li>
          <li>
            Zewas beh&auml;lt sich das Recht vor, Registrierungen zu
            l&ouml;schen, wenn der Nutzer sich l&auml;nger als 12 Monate nicht
            angemeldet hat.
          </li>
          <li>
            F&uuml;r die bei der Registrierung erhobenen personenbezogenen Daten
            des Nutzers gelten die Datenschutzbestimmungen der Plattform.
          </li>
        </ul>
        <h2>5. Pflichten der Nutzer</h2>
        <h3>5.1. Sicherung von Daten</h3>
        <ul>
          <li>
            Die Nutzer tragen die Verantwortung daf&uuml;r, Informationen, die
            sie &uuml;ber die Plattformdienste einsehen und die von Zewas
            gespeichert werden, f&uuml;r Zwecke wie Beweissicherung,
            Buchf&uuml;hrung oder andere Zwecke, auf einem von Zewas
            unabh&auml;ngigen Speichermedium zu archivieren.
          </li>
        </ul>
        <h3>5.2. Nutzungsverhalten</h3>
        <ul>
          <li>
            Die Nutzer sind verpflichtet, mit ihrem Nutzungsverhalten keine
            gesetzlichen Vorschriften, Rechte Dritter oder diese AGB zu
            verletzen.
          </li>
          <li>
            Ein Versto&szlig; im Sinne von Punkt 5.2.1 der AGB liegt
            insbesondere vor, wenn ein Nutzer:
          </li>
        </ul>
        <ul>
          <li>
            sein Konto &uuml;bertr&auml;gt oder Dritten Zugang dazu
            gew&auml;hrt,
          </li>
          <li>
            andere Nutzer oder Zewas in erheblichem Ma&szlig;e sch&auml;digt.
            <ul>
              <li>
                Bei einem Versto&szlig; im Sinne von Punkt 5.2.1 der AGB kann
                Zewas folgende Ma&szlig;nahmen ergreifen:
              </li>
            </ul>
          </li>
          <li>Verwarnung des Nutzers</li>
          <li>
            Vorl&auml;ufige oder endg&uuml;ltige Sperrung des Nutzerkontos
          </li>
          <li>
            Einschr&auml;nkung der Nutzung der Plattformdienste
            <ul>
              <li>
                Bei der Wahl einer Ma&szlig;nahme ber&uuml;cksichtigt Zewas die
                berechtigten Interessen des betroffenen Nutzers, anderer Nutzer
                der Plattform, eventueller Rechteinhaber und sonstiger Dritter
                sowie seine eigenen berechtigten Interessen als Diensteanbieter.
                Dabei wird insbesondere die Schwere des Versto&szlig;es sowie
                m&ouml;gliche Hinweise darauf, dass der Nutzer den Versto&szlig;
                nicht verschuldet hat, ber&uuml;cksichtigt. Zewas pr&uuml;ft
                zudem, ob die Ma&szlig;nahme nur bei Vorliegen eines wichtigen
                Grundes ergriffen werden kann, insbesondere wenn sie zu
                erheblichen Nachteilen f&uuml;r den betroffenen Nutzer
                f&uuml;hren k&ouml;nnte. Wenn eine Ma&szlig;nahme ergriffen
                wird, informiert Zewas den betroffenen Nutzer unter Angabe der
                Gr&uuml;nde im Rahmen der gesetzlichen Verpflichtungen.
              </li>
            </ul>
          </li>
        </ul>
        <h2>6. Preise und Zahlungsbedingungen</h2>
        <h3>6.1. Zahlungsdienstleiser</h3>
        <ul>
          <li>
            Die Zahlungsabwicklung f&uuml;r die Nutzung der Dienste der
            Plattform erfolgt &uuml;ber den Zahlungsdienstleister Stripe. Mit
            der Nutzung der Plattform akzeptiert der Nutzer die
            Nutzungsbedingungen von Stripe.
          </li>
        </ul>
        <h3>6.3. Preisgestaltung</h3>
        <ul>
          <li>
            Die Preisgestaltung wird ausschlie&szlig;lich von den Wegbetreibern
            festgelegt. Die Plattform hat keinen Einfluss auf die Festlegung der
            Preise und &uuml;bernimmt keine Verantwortung daf&uuml;r.
          </li>
          <li>
            Sofern nicht anders angegeben (insbesondere bei
            grenz&uuml;berschreitenden Verk&auml;ufen), versteht sich der Preis
            der jeweiligen Artikel als Endpreis, der eine eventuelle
            Mehrwertsteuer und alle weiteren Preisbestandteile
            einschlie&szlig;t.
          </li>
        </ul>
        <h2>7. Wartungen</h2>
        <h3>7.1. Wartungsarbeiten</h3>
        <ul>
          <li>
            Zewas kann seine Leistungen zeitweilig einschr&auml;nken, wenn dies
            aufgrund von Kapazit&auml;tsgrenzen, zur Sicherheit oder
            Integrit&auml;t der Server oder zur Durchf&uuml;hrung technischer
            Ma&szlig;nahmen erforderlich ist und der ordnungsgem&auml;&szlig;en
            oder verbesserten Erbringung der Leistungen dient
            (Wartungsarbeiten). In solchen F&auml;llen ber&uuml;cksichtigt Zewas
            die berechtigten Interessen der Nutzer, beispielsweise durch
            Vorabinformationen.
          </li>
        </ul>
        <h2>8. K&uuml;ndigung</h2>
        <h3>8.1. K&uuml;ndigungserkl&auml;rung des Nutzers</h3>
        <ul>
          <li>
            Nutzer k&ouml;nnen die Services der Plattform jederzeit
            k&uuml;ndigen. Hierf&uuml;r wird ein Kontaktformular mit einem
            K&uuml;ndigungsformular zur Verf&uuml;gung gestellt. Ab diesem
            Zeitpunkt ist es f&uuml;r den Nutzer nicht mehr m&ouml;glich, die
            registrierungspflichten Services der Plattform zu nutzen.
          </li>
        </ul>
        <h3>8.2. K&uuml;ndigungserkl&auml;rung Zewas</h3>
        <ul>
          <li>
            Zewas kann die Services jederzeit mit einer Frist von 14 Tagen
            gegen&uuml;ber den Nutzern k&uuml;ndigen. Das Recht zur Sperrung
            einer Registrierung bleibt davon unber&uuml;hrt.
          </li>
        </ul>
        <h2>9. Haftung</h2>
        <h3>9.1. Haftungsausschluss</h3>
        <ul>
          <li>
            F&uuml;r Sch&auml;den, die dem Nutzer im Zusammenhang mit den
            Services der Plattform durch die Plattform, Zewas,
            Unterauftragnehmer oder deren jeweilige Erf&uuml;llungsgehilfen
            entstehen, gelten folgende Regelungen: Bei Vorsatz oder grober
            Fahrl&auml;ssigkeit sowie bei Vorliegen einer Garantie haften sie
            unbeschr&auml;nkt. Bei leicht fahrl&auml;ssiger Verletzung
            wesentlicher Vertragspflichten ist die Haftung auf die H&ouml;he der
            vorhersehbaren, typischerweise eintretenden Sach- und
            Verm&ouml;genssch&auml;den beschr&auml;nkt. Jede weitergehende
            Haftung auf Schadenersatz ist ausgeschlossen.
          </li>
          <li>
            Zewas haftet nur f&uuml;r Datenverluste im Rahmen der vorstehenden
            Bestimmungen nur in dem Umfang, der auch dann nicht vermieden worden
            w&auml;re, wenn der Nutzer die bei ihm vorhandenen Daten jeweils im
            letzten bearbeiteten Zustand in maschinenlesbarer Form gesichert
            h&auml;tte.
          </li>
          <li>
            Ein etwaiges Mitverschulden des Nutzers ist in jedem Fall zu
            ber&uuml;cksichtigen.
          </li>
        </ul>
        <p>
          9.3.4. Die oben genannten Bestimmungen gelten auch zugunsten der
          Mitarbeiter von Zewas f&uuml;r ihre Leistungen im Zusammenhang mit der
          Plattform.
        </p>
        <h2>10. Gew&auml;hrleistung</h2>
        <h3>10.1. Richtigkeit der Daten</h3>
        <ul>
          <li>
            Zewas &uuml;bernimmt keine Gew&auml;hr f&uuml;r die Richtigkeit und
            Vollst&auml;ndigkeit der von Nutzern oder Wegbetreibern gemachten
            Angaben und abgegebenen Erkl&auml;rungen sowie f&uuml;r die
            Identit&auml;t und Integrit&auml;t der Nutzer oder Wegbetreiber.
          </li>
          <li>
            Die in die Datenbank eingestellten Inhalte bzw. Inserate sind fremde
            Inhalte f&uuml;r Zewas. Die rechtliche Verantwortung f&uuml;r diese
            Inhalte liegt bei der Person, die sie in die Datenbank eingestellt
            hat.
          </li>
        </ul>
        <h3>10.2. Gew&auml;hrleistungsausschluss</h3>
        <ul>
          <li>
            Zewas &uuml;bernimmt keine Gew&auml;hrleistung f&uuml;r technische
            M&auml;ngel, insbesondere nicht f&uuml;r die st&auml;ndige und
            ununterbrochene Verf&uuml;gbarkeit der Datenbank und ihrer Inhalte
            oder f&uuml;r die vollst&auml;ndige und fehlerfreie Wiedergabe der
            Inhalte, die vom Nutzer in die Datenbank eingestellt wurden.
          </li>
        </ul>
        <h2>11. Schlussbestimmungen</h2>
        <h3>11.1. &Uuml;bertragung der Rechtsverh&auml;ltnisse</h3>
        <ul>
          <li>
            Zewas ist berechtigt, seine Rechte und Pflichten aus diesem
            Vertragsverh&auml;ltnis ganz oder teilweise mit einer
            Ank&uuml;ndigungsfrist von vier Wochen auf einen Dritten zu
            &uuml;bertragen. In diesem Fall steht dem Nutzer jederzeit das Recht
            zur fristlosen und kostenfreien K&uuml;ndigung zu.
          </li>
        </ul>
        <h3>11.2. &Auml;nderung der AGB</h3>
        <ul>
          <li>
            Zewas kann dem Nutzer jederzeit mit einer Vorlaufzeit von mindestens
            30 Tagen vor dem vorgeschlagenen Zeitpunkt ihres Wirksamwerdens in
            Textform (z.B. per E-Mail) eine &Auml;nderung dieser AGB
            vorschlagen. Die vorgeschlagene &Auml;nderung tritt nur in Kraft,
            wenn der Nutzer wie folgt zustimmt:
          </li>
        </ul>
        <ul>
          <li>
            Bei wesentlichen &Auml;nderungen bittet Zewas den Nutzer um seine
            ausdr&uuml;ckliche Zustimmung zu diesen &Auml;nderungen.
          </li>
          <li>
            Bei nicht wesentlichen &Auml;nderungen gilt die Zustimmung des
            Nutzers als erteilt, wenn er die &Auml;nderung nicht vor dem
            vorgeschlagenen Datum des Inkrafttretens in Textform (z.B. per
            E-Mail) abgelehnt hat.
          </li>
        </ul>
        <p>
          Zewas informiert den Nutzer bei solchen &Auml;nderungen
          ausf&uuml;hrlich &uuml;ber sein Recht, die &Auml;nderung abzulehnen,
          die Frist daf&uuml;r und die Konsequenzen der Vers&auml;umung dieser
          Frist sowie &uuml;ber das K&uuml;ndigungsrecht des Nutzers.
        </p>
        <h3>11.3. Schriftformgebot</h3>
        <ul>
          <li>
            S&auml;mtliche Erkl&auml;rungen, die im Rahmen des mit Zewas
            abgeschlossenen Nutzungsvertrag &uuml;bermittelt werden, m&uuml;ssen
            in Schriftform oder Textform (wie zum Beispiel per E-Mail) erfolgen.
          </li>
        </ul>
        <h3>11.4. Salvatorische Klausel</h3>
        <ul>
          <li>
            Sollten einzelne Bestimmungen dieser Allgemeinen
            Gesch&auml;ftsbedingungen unwirksam sein oder werden, hat dies keine
            Auswirkungen auf die Wirksamkeit der &uuml;brigen Bestimmungen und
            der unter ihrer Geltung geschlossenen Vertr&auml;ge. Die unwirksame
            Bestimmung ist durch eine wirk- same Regelung zu ersetzen, die dem
            Sinn und Zweck der unwirksamen Bestimmung am n&auml;chsten kommt.
          </li>
        </ul>
        <h3>11.5. Anwendbares Recht</h3>
        <ul>
          <li>
            Die Rechtsverh&auml;ltnisse zwischen Zewas und dem Nutzer
            unterliegen &ouml;sterreichischem Recht unter Ausschluss des
            UN-Kaufrechts.
          </li>
        </ul>
        <h3>11.6.Gerichtsstand</h3>
        <ul>
          <li>
            Der Gerichtsstand f&uuml;r alle Streitigkeiten ist Wien. Dies gilt
            nicht, wenn es sich um ein Verbrauchergesch&auml;ft gem&auml;&szlig;
            &sect; 1 KSchG (Konsumentenschutzgesetz) handelt, in diesem Fall
            gilt der gesetzliche Gerichtsstand f&uuml;r
            Verbrauchergesch&auml;fte.
          </li>
        </ul>
        <Footer />
      </InnerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
`;
const InnerWrapper = styled.div`
  padding: 15px;
  width: 60vw;

  @media (max-width: 1700px) {
    width: 80vw;
  }
`;
const HeadingWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
`;
const Close = styled.a`
  color: #165e16;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  @media (max-width: 900px) {
    display: none;
  }
`;

export default Agb;
