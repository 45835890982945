import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import { useExploreState } from "../../../context/ExploreContext";
import Marker from "./Marker";
import { gql, useQuery } from "@apollo/client";

let polylines = [];

const GET_ROADS = gql`
  query GetLocations {
    roads {
      id
      name
      description
      image
      location {
        latitude
        longitude
      }
    }
  }
`;

const Map = () => {
  const [mapLoaded, setMapLoaded] = useState();
  const [mapReference, setMapReference] = useState();
  const [mapsReference, setMapsReference] = useState();
  const { data } = useQuery(GET_ROADS);
  //const [markers, setMarkers] = useState([]);

  let markers = data?.roads || [];

  const { explore } = useExploreState();

  /*useEffect(() => {
        firebase.firestore().collection('roads').get().then(querySnapshot => {
            let tempMarkers = querySnapshot.docs.map(doc => {
                return { roadId: doc.id, name: doc.data().name, location: doc.data().location, image: doc.data().image }
            });
            setMarkers(tempMarkers);
        });
    }, []);*/

  const onMapLoaded = (map, maps) => {
    setMapReference(map);
    setMapsReference(maps);
    setMapLoaded(true);
  };

  const getCoordinates = () => {
    const coordinates = [];
    if (explore?.zones?.length > 0) {
      const zones = [
        ...explore.selectedZones,
        ...explore.disabledZones,
        ...explore.requiredZones,
      ];
      for (const zoneId of zones) {
        coordinates.push(
          explore.zones
            .find((zone) => zone.id === zoneId)
            .coordinates.map((coordinate) => {
              return { lat: coordinate.latitude, lng: coordinate.longitude };
            })
        );
      }
    }
    return coordinates;
  };

  const afterMapLoadChanges = () => {
    for (const polyline of polylines) {
      polyline.setMap(null);
    }
    polylines = [];
    let bounds = new mapsReference.LatLngBounds();
    let coordinates = getCoordinates();

    if (coordinates.length > 0) {
      const fullCoordinates = [];
      for (const coordinate of coordinates) {
        const polyline1 = new mapsReference.Polyline({
          geodesic: true,
          strokeColor: "white",
          strokeOpacity: 1.0,
          strokeWeight: 7,
        });
        polylines.push(polyline1);
        polyline1.setPath(coordinate);
        polyline1.setMap(mapReference);
        const polyline2 = new mapsReference.Polyline({
          geodesic: true,
          strokeColor: "#165E16",
          strokeOpacity: 1.0,
          strokeWeight: 4,
        });
        polylines.push(polyline2);
        polyline2.setPath(coordinate);
        polyline2.setMap(mapReference);
        fullCoordinates.push(...coordinate);
      }
      zoomToObject(fullCoordinates);
    }

    return (
      <React.Fragment>
        {markers.map((marker, index) => {
          bounds.extend(
            new mapsReference.LatLng(
              marker.location.latitude,
              marker.location.longitude
            )
          );
          if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
            var extendPoint1 = new mapsReference.LatLng(
              bounds.getNorthEast().lat() + 0.1,
              bounds.getNorthEast().lng() + 0.1
            );
            var extendPoint2 = new mapsReference.LatLng(
              bounds.getNorthEast().lat() - 0.1,
              bounds.getNorthEast().lng() - 0.1
            );
            bounds.extend(extendPoint1);
            bounds.extend(extendPoint2);
          }
          return (
            <Marker
              data={marker}
              map={mapReference}
              maps={mapsReference}
              key={index}
            />
          );
        })}
        {coordinates.length <= 0 && mapReference.fitBounds(bounds)}
      </React.Fragment>
    );
  };

  const zoomToObject = (coordinates) => {
    var bounds = new mapsReference.LatLngBounds();
    for (const coordinate of coordinates) {
      bounds.extend(coordinate);
    }
    mapReference.fitBounds(bounds);
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: "AIzaSyA3KBXBXjuiQYpCzxReRZcZdhmccoK5t-I" }}
      defaultCenter={{
        lat: 59.95,
        lng: 30.33,
      }}
      defaultZoom={10}
      options={{
        fullscreenControl: false,
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: mapsReference?.ControlPosition.RIGHT_TOP,
          mapTypeIds: [
            mapsReference?.MapTypeId.ROADMAP,
            mapsReference?.MapTypeId.SATELLITE,
            mapsReference?.MapTypeId.HYBRID,
          ],
        },
      }}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => onMapLoaded(map, maps)}
    >
      {mapLoaded && afterMapLoadChanges()}
    </GoogleMapReact>
  );
};

export default Map;
