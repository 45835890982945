import React from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import InputWithIcon from "./InputWithIcon";
import InputContainer from "./InputContainer";

const SearchInput = ({ marginTop, value, onChange }) => {
  return (
    <InputContainer title={"Weg suchen"} marginTop={marginTop}>
      <form noValidate action="" role="search">
        <InputWithIcon
          type="search"
          placeholder={"Nach Weg suchen"}
          value={value}
          onChange={(event) => onChange(event.currentTarget.value)}
          Icon={value ? FaTimes : FaSearch}
          onIconClick={
            value
              ? () => {
                  onChange("");
                }
              : undefined
          }
        />
      </form>
    </InputContainer>
  );
};

export default SearchInput;
