import React, { useEffect, useState } from "react";
import Card from "../Sidebar";
import SearchItem from "./SearchItem";
import SearchInput from "../../Inputs/SearchInput";
import { setRoad } from "../../../functions/helpers";
import { useExploreDispatch } from "../../../context/ExploreContext";
import { useLocation, useHistory } from "react-router-dom";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import MiniSearch from "minisearch";
import styled from "styled-components";

function useBrowserQuery() {
  return new URLSearchParams(useLocation().search);
}

const GET_ALL_ROADS = gql`
  query Query {
    roads {
      name
      description
      location {
        latitude
        longitude
      }
      image
      id
    }
  }
`;

const GET_ROAD = gql`
  query Query($roadId: Int!) {
    road(id: $roadId) {
      id
      image
      name
      description
      location {
        latitude
        longitude
      }
      zones {
        id
        name
        passRates {
          passRate {
            id
          }
          price
        }
        coordinates {
          latitude
          longitude
        }
        required
        dependsOn {
          id
        }
      }
    }
  }
`;

let miniSearch = new MiniSearch({
  fields: ["name", "description"], // fields to index for full-text search
  storeFields: ["name", "description", "location", "image", "coordinates"], // fields to return with search results
  searchOptions: {
    boost: { name: 2 },
    fuzzy: 0.2,
    prefix: true,
  },
});
const Search = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [fetchRoad] = useLazyQuery(GET_ROAD);
  const { data } = useQuery(GET_ALL_ROADS, {
    onCompleted: (data) => {
      miniSearch.addAll(data.roads);
    },
  });
  const dispatchExplore = useExploreDispatch();
  let query = useBrowserQuery();
  const history = useHistory();
  useEffect(() => {
    if (query.get("roadId")) {
      fetchRoad({
        variables: { roadId: parseInt(query.get("roadId")) },
        onCompleted: (data) => {
          data = data.road;
          setRoad(
            data.id,
            data.name,
            data.description,
            data.location,
            data.image,
            data.zones,
            dispatchExplore
          );
          const requiredZones = [];
          for (const zone of data.zones) {
            if (zone.required) {
              requiredZones.push(zone.id);
            }
          }
          dispatchExplore({
            type: "setZone",
            data: {
              selectedZones: requiredZones.length > 0 ? [] : [data.zones[0].id],
              disabledZones: [],
              requiredZones: requiredZones,
            },
          });
          query.delete("roadId");
          history.replace({
            search: query.toString(),
          });
        },
      });
    }
  });

  // Search with default options
  let results = miniSearch.search(searchTerm);
  return (
    <Card headline="Weg suchen" width="500px">
      <SearchInput value={searchTerm} onChange={setSearchTerm} />
      <div style={{ height: "20px" }}></div>
      {searchTerm
        ? results.map((value) => <SearchItem hit={value} key={value.id} />)
        : data?.roads &&
          data.roads.map((value) => <SearchItem hit={value} key={value.id} />)}
    </Card>
  );
};

const Text = styled.p`
  margin-top: 15px;
  text-align: center;
  margin-bottom: 14px;
  font-weight: 600;
  font-size: 16px;
  color: black;
  background-color: #165e16;
  border-radius: 10px;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  color: white;
  cursor: pointer;
  border: 1px solid gray;
  &:hover {
    background-color: white;
    text-decoration: none;
    color: black;
  }
`;

export default Search;
