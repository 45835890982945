import styled from "styled-components";

const Title = styled.h2`
  color: #165e16;
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 20px;
`;

export default Title;
