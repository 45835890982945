import React from "react";
import Linktext from "../General/Linktext";
import styled from "styled-components";

const Sidebar = ({ children }) => {
  const mobile = window.innerWidth < 740;
  const width = mobile ? "100vw" : "400px";
  return (
    <Container width={width} mobile={mobile}>
      <div style={{ width: width }}></div>
      <ChildrenContainer width={width}>{children}</ChildrenContainer>
      <Footer width={width}>
        <Linktext url="/privacy">Datenschutz</Linktext>
        <Linktext url="/imprint">Impressum</Linktext>
        <Linktext url="/agb">AGB</Linktext>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  background-color: white;
  margin: 0px;
  width: ${(props) => props.width};
  border-right: ${(props) => (props.mobile ? "none" : "1px solid #707070")};
  box-shadow: 0px 3px 6px #00000029;
  z-index: 100;
  display: flex;
  flex-flow: column;
  height: 100%;
`;

const ChildrenContainer = styled.div`
  padding: 20px;
  max-height: calc(100vh - 168px);
  overflow-y: auto;
  scrollbar-width: thin;
  flex: 1;
`;

const Footer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: ${(props) => props.width};
  height: 64px;
  border-top: 1px solid #707070;
  display: flex;
  align-items: center;
  background-color: white;
`;

export default Sidebar;
