import React from "react";
import Sidebar from "./Sidebar";
import ScaleLoader from "react-spinners/ScaleLoader";

const Info = () => {
  return (
    <Sidebar headline="Weginformationen" width="500px">
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <ScaleLoader
          color="#165E16"
          loading={true}
          height={35}
          width={4}
          radius={2}
          margin={2}
        />
      </div>
    </Sidebar>
  );
};

export default Info;
