import React from 'react';
import InputContainer from './InputContainer'
import styled from 'styled-components';

const TextInput = ({ marginTop, value, onChange, placeholder, leftNotRounded, rightNotRounded, title }) => {
    return (
        <InputContainer title={title} marginTop={marginTop} rightNotRounded={rightNotRounded} leftNotRounded={leftNotRounded}>
            <Input type="text" value={value} onChange={onChange} placeholder={placeholder} />
        </InputContainer>
    );
}

const Input = styled.input`
    background: transparent;
    margin: 0px;
    text-decoration: none;
    outline: none;
    border: none;
    font-family: "Montserrat";
    font-size: 15px;
    width: 100%;
    padding: 10px;
`

export default TextInput;