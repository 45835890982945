import React from 'react';
import InputContainer from './InputContainer'
import styled from 'styled-components';

const TextInput = ({ marginTop, children, title, color }) => {
    return (
        <InputContainer title={title} border={false} marginTop={marginTop} >
            <Text color={color}>{children}</Text>
        </InputContainer>
    );
}

const Text = styled.p`
    margin: 0px;
    font-size: 15px;
    color: ${props => props.color};
`

export default TextInput;