import React from "react";
import styled from "styled-components";
import Footer from "../Footer/Footer";

const Imprint = () => {
  return (
    <Wrapper>
      <InnerWrapper>
        <HeadingWrapper>
          <h1>Impressum</h1>
          <Close href="/">X</Close>
        </HeadingWrapper>
        <div>
          <h2> Informationen über den Diensteanbieter.</h2> <p>Zewas GmbH</p>
          <p>
            Gerbe 525 <br /> 6863 Egg <br /> Österreich
          </p>
          <p>
            <b>Vertretungsberechtigte Personen:</b> Niklas Mischi <br />
            <b>Tel.: </b>
            <a style={{ textDecoration: "underline" }} href="tel:+436606290593">
              +43 660 6290593
            </a>{" "}
            <br />
            <b>Web: </b>
            <a
              style={{ textDecoration: "underline" }}
              href="https://zewas.digital"
              target="blank"
            >
              https://zewas.digital
            </a>
            <br />
            <b>E-Mail: </b>
            <a
              style={{ textDecoration: "underline" }}
              href="mailto:office@zewas.at"
            >
              office@zewas.at
            </a>
          </p>
          <div>
            <b>Firmenbuchnummer:</b> FN 581379 w
          </div>
          <div>
            <b>Firmenbuchgericht:</b> Landesgericht Feldkirch
          </div>
          <div>
            <b>Firmensitz:</b> 6836 Egg
          </div>
          <div>
            <b>UID-Nummer:</b> ATU78150304
          </div>
          <div>
            <b>Mitglied bei:</b> Mitglied der Wirtschaftskammer Vorarlberg
            Fachgruppe Werbung und Kommunikation
          </div>
          <div>
            <b>Berufsrecht:</b> Gewerbeordnung (GewO):
            www.ris.bka.gv.at/Bundesrecht
          </div>
          <br />
          <div>
            <b>Aufsichtsbehörde/Gewerbebehörde:</b> Bezirkshauptmannschaft
            Bregenz
          </div>
          <h2>EU-Streitschlichtung</h2>
          <p>
            Gemäß Verordnung über Online-Streitbeilegung in
            Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die
            Online-Streitbeilegungsplattform (OS-Plattform) informieren.
            Verbraucher haben die Möglichkeit, Beschwerden an die Online
            Streitbeilegungsplattform der Europäischen Kommission unter{" "}
            <a
              style={{ textDecoration: "underline" }}
              target="blank"
              href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE"
            >
              https://ec.europa.eu
            </a>{" "}
            zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb
            in unserem Impressum. Wir möchten Sie jedoch darauf hinweisen, dass
            wir nicht bereit oder verpflichtet sind, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>
          <h2>Haftung für Inhalte dieser Website</h2>
          <p>
            Wir entwickeln die Inhalte dieser Website ständig weiter und bemühen
            uns korrekte und aktuelle Informationen bereitzustellen. Leider
            können wir keine Haftung für die Korrektheit aller Inhalte auf
            dieser Website übernehmen, speziell für jene, die seitens Dritter
            bereitgestellt wurden. Als Diensteanbieter sind wir nicht
            verpflichtet, die von Ihnen übermittelten oder gespeicherten
            Informationen zu überwachen oder nach Umständen zu forschen, die auf
            eine rechtswidrige Tätigkeit hinweisen.
          </p>
          <p>
            Unsere Verpflichtungen zur Entfernung von Informationen oder zur
            Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
            aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben
            auch im Falle unserer Nichtverantwortlichkeit davon unberührt.
          </p>
          <p>
            Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen,
            bitte wir Sie uns umgehend zu kontaktieren, damit wir die
            rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten
            im Impressum.
          </p>
          <h2>Haftung für Links auf dieser Website</h2>
          <p>
            Unsere Website enthält Links zu anderen Websites für deren Inhalt
            wir nicht verantwortlich sind. Haftung für verlinkte Websites
            besteht für uns nicht, da wir keine Kenntnis rechtswidriger
            Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch
            bisher nicht aufgefallen sind und wir Links sofort entfernen würden,
            wenn uns Rechtswidrigkeiten bekannt werden.
          </p>
          <p>
            Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte
            wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten im
            Impressum.
          </p>
          <h2>Urheberrechtshinweis</h2>
          <p>
            Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos)
            unterliegen dem Urheberrecht. Bitte fragen Sie uns bevor Sie die
            Inhalte dieser Website verbreiten, vervielfältigen oder verwerten
            wie zum Beispiel auf anderen Websites erneut veröffentlichen. Falls
            notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte
            unserer Seite rechtlich verfolgen.
          </p>
          <p>
            Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht
            verletzen, bitten wir Sie uns zu kontaktieren.
          </p>
          <p>Alle Texte sind urheberrechtlich geschützt.</p>
          <p>
            Quelle: Erstellt mit dem{" "}
            <a
              style={{ textDecoration: "underline" }}
              href="https://www.adsimple.at/impressum-generator/"
              target="blank"
            >
              Impressum Generator Österreich
            </a>{" "}
            von AdSimple
          </p>
        </div>{" "}
        <Footer />
      </InnerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
`;
const HeadingWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
`;
const Close = styled.a`
  color: #165e16;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  @media (max-width: 900px) {
    display: none;
  }
`;

const InnerWrapper = styled.div`
  padding: 15px;
  width: 60vw;
  @media (max-width: 1700px) {
    width: 80vw;
  }
`;

export default Imprint;
