import React, { useState } from "react";
import styled from "styled-components";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import InputContainer from "./InputContainer";
import InputWithIcon from "./InputWithIcon";
import { useOutsideAlerter } from "../../functions/helpers";

const SelectInput = ({
  marginTop,
  options,
  onSelect,
  title,
  placeholder,
  value,
  rightNotRounded,
  leftNotRounded,
  maxWidth,
}) => {
  const [searchText, setSearchText] = useState("");
  const [active, setActive] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);

  const filterOptions = (searchString) => {
    setSearchText(searchString);
    let result = options.filter((option) =>
      option.value.toLowerCase().includes(searchString.toLowerCase())
    );
    setFilteredOptions(result);
  };

  const select = (key) => {
    onSelect(key);
  };

  const wrapperRef = React.useRef(null);

  useOutsideAlerter(wrapperRef, () => {
    setActive(false);
    setSearchText("");
  });

  return (
    <div style={{ maxWidth: maxWidth, position: "relative" }}>
      <InputContainer
        title={title}
        marginTop={marginTop}
        rightNotRounded={rightNotRounded}
        leftNotRounded={leftNotRounded}
        bottomNotRounded={active}
      >
        <div ref={wrapperRef}>
          <InputWithIcon
            type="search"
            placeholder={
              options.find((x) => x.key === value)?.value || placeholder
            }
            value={searchText}
            onClick={() => setActive(true)}
            onChange={(event) => filterOptions(event.currentTarget.value)}
            Icon={active ? FaAngleUp : FaAngleDown}
            onIconClick={() => setActive(!active)}
          />
          {active && (
            <ItemsWrapper>
              {filteredOptions.map((option) => (
                <Item key={option.key} onClick={() => select(option.key)}>
                  <Input
                    type="radio"
                    value={option.key}
                    checked={option.key === value}
                    readOnly
                  />
                  <Label>{option.value}</Label>
                </Item>
              ))}
            </ItemsWrapper>
          )}
        </div>
      </InputContainer>
    </div>
  );
};

const ItemsWrapper = styled.div`
  max-height: 180px;
  overflow-y: auto;
  scrollbar-width: thin;
  background-color: white;
  position: absolute;
  border: 1px solid #b2c3cc;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const Line = styled.hr`
  margin: 0px;
  height: 1px;
  background-color: #b2c3cc;
  border-width: 0px;
`;

const Item = styled.div`
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #f8f8f8;
  }
`;

const Input = styled.input`
  margin: 0px;
  margin-right: 20px;
  cursor: pointer;
  height: 15px;
`;

const Label = styled.label`
  font-size: 15px;
  cursor: pointer;
`;

export default SelectInput;
