import { gql, useQuery } from "@apollo/client";
import {
  useExploreState,
  useExploreDispatch,
} from "../../../context/ExploreContext";
import { setRoad } from "../../../functions/helpers";

const GET_ZONES_FOR_ROAD = gql`
  query Query($roadId: Int!) {
    zonesByRoadId(roadId: $roadId) {
      coordinates {
        latitude
        longitude
      }
      id
      name
      required
      dependsOn {
        id
      }
      passRates {
        passRate {
          id
        }
        price
      }
    }
  }
`;

const Marker = ({ data, map, maps }) => {
  const dispatchExplore = useExploreDispatch();
  const { explore } = useExploreState();
  const res = useQuery(GET_ZONES_FOR_ROAD, {
    variables: { roadId: data.id },
    onCompleted: (data) => {},
  });

  const renderMarker = () => {
    const marker = new maps.Marker({
      position: {
        lat: data.location.latitude,
        lng: data.location.longitude,
      },
      map,
      title: data.name,
    });
    marker.addListener("click", () => {
      if (data.id === explore.roadId) {
        dispatchExplore({ type: "reset" });
      } else {
        setRoad(
          data.id,
          data.name,
          data.description,
          data.location,
          data.image,
          res.data.zonesByRoadId,
          dispatchExplore
        );
        const requiredZones = [];
        for (const zone of res.data.zonesByRoadId) {
          if (zone.required) {
            requiredZones.push(zone.id);
          }
        }
        dispatchExplore({
          type: "setZone",
          data: {
            selectedZones:
              requiredZones.length > 0 ? [] : [res.data.zonesByRoadId[0].id],
            disabledZones: [],
            requiredZones: requiredZones,
          },
        });
      }
    });
  };

  renderMarker();

  return null;
};

export default Marker;
