import React from "react";
import styled from "styled-components";
import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
import { useLocation, Link } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Failure = () => {
  let query = useQuery();
  return (
    <Wrapper>
      <FailureIcon />
      <Headline>Da ist was schiefgelaufen!</Headline>
      <p style={{ textAlign: "center", maxWidth: "500px" }}>
        Versuche es später noch einmal.
        <br />
        <br />
        Falls dies bereits ein erneuter Versuch ist, kontaktiere uns:{" "}
        <a href={"mailto:info@mauto.at"}>info@mauto.at</a>
        <br />
        Gib dabei folgende Kennziffer an: {query.get("id")}
      </p>
      <Link to="/">Zurück zur Übersicht</Link>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const FailureIcon = styled(CloseTwoToneIcon)`
  color: #d1495b;
  font-size: 200px;
`;

const Headline = styled.h3`
  font-weight: 700;
  font-size: 20px;
`;

export default Failure;
