import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const SiteContainer = (props) => {
    return (
        <Container
            initial="initial"
            animate="in"
            exit="out"
            transition={pageTransition}
            variants={props.position === 'top' ? topPageVariants : bottomPageVariants}
        >
            {props.children}
        </Container>
    );
}

const Container = styled(motion.div)`
  height: 100%;
  width: 100%;
  position: absolute;
`

const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5
};

const topPageVariants = {
    initial: {
        y: '100vh',
    },
    in: {
        y: 0,
    },
    out: {
        y: '100vh',
    },
}

const bottomPageVariants = {
    initial: {
        y: '-100vh',
    },
    in: {
        y: 0,
    },
    out: {
        y: '-100vh',
    },
}

export default SiteContainer;