import React from "react";
import styled from "styled-components";

const Linktext = ({ children, url }) => {
  return <Text href={url}>{children}</Text>;
};

const Text = styled.a`
  color: #165e16;
  font-size: 14px;
  margin-left: 15px;
`;

export default Linktext;
