import React from "react";
import ButtonContainer from "../../styled-components/ButtonContainer";
import styled from "styled-components";
import { FaAngleRight, FaAngleLeft, FaMap } from "react-icons/fa";
import {
  useExploreState,
  useExploreDispatch,
} from "../../../context/ExploreContext";
import Sidebar from "../Sidebar";
import SelectInput from "../../Inputs/SelectInput";
import Button from "../../Inputs/Button";
import Title from "../Title";
import { SRLWrapper } from "simple-react-lightbox";
import Checkbox from "../../Inputs/Checkbox";
import TextWithLineBreaks from "../../General/TextWithLineBreaks";

const Info = ({ setShowSidebar, showSidebar, mobile }) => {
  const { explore } = useExploreState();
  const dispatchExplore = useExploreDispatch();

  const getFormattedZones = () => {
    return explore.zones.map((zone, index) => {
      return { key: index, value: zone.name };
    });
  };

  const onSelect = (zoneId) => {
    const oldSelectedZones = [
      ...explore.selectedZones,
      ...explore.disabledZones,
    ];
    const selectedZones = [];
    let disabledZones = [];

    if (oldSelectedZones.includes(zoneId)) {
      const index = oldSelectedZones.indexOf(zoneId);
      oldSelectedZones.splice(index, 1);
    } else {
      oldSelectedZones.push(zoneId);
    }

    for (const zoneId of oldSelectedZones) {
      disabledZones.push(
        ...explore.zones
          .find((zone) => zone.id === zoneId)
          .dependsOn.map((zone) => zone.id)
      );
    }

    disabledZones = [...new Set(disabledZones)];

    for (const zoneId of oldSelectedZones) {
      if (!disabledZones.includes(zoneId)) {
        selectedZones.push(zoneId);
      }
    }

    dispatchExplore({
      type: "setZone",
      data: {
        requiredZones: explore.requiredZones,
        selectedZones: selectedZones,
        disabledZones: disabledZones,
      },
    });
  };

  const onNextClick = () => {
    dispatchExplore({ type: "setInfoToDetail" });
  };

  const onBackClick = () => {
    dispatchExplore({ type: "reset" });
  };

  const options = {
    caption: {
      showCaption: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
    },
  };

  return (
    <Sidebar headline="Weginformationen" width="500px">
      <Title>{explore.roadName}</Title>
      <SRLWrapper options={options}>
        <Image src={explore.roadImage} alt={explore.roadName} />
      </SRLWrapper>
      <TextWithLineBreaks text={explore.description} />
      <p style={{ fontWeight: 600, marginBottom: "10px", marginTop: "20px" }}>
        Zonen auswählen:
      </p>
      {explore.zones.map((zone) => (
        <div style={{ marginBottom: "15px" }} key={zone.id}>
          <label>
            <Checkbox
              checked={
                explore.selectedZones.includes(zone.id) ||
                explore.disabledZones.includes(zone.id) ||
                explore.requiredZones.includes(zone.id)
              }
              onChange={() => onSelect(zone.id)}
              disabled={
                explore.disabledZones.includes(zone.id) ||
                explore.requiredZones.includes(zone.id)
              }
            />
            <span style={{ marginLeft: "15px" }}>{zone.name}</span>
          </label>
        </div>
      ))}
      {mobile && (
        <MapButtonConatiner>
          <Button
            Icon={FaMap}
            left={false}
            text="Auf Karte anzeigen"
            onClick={() => setShowSidebar(false)}
          />
        </MapButtonConatiner>
      )}
      <ButtonContainer>
        <Button
          Icon={FaAngleLeft}
          left={true}
          text="Zurück"
          onClick={onBackClick}
        />
        <Button
          Icon={FaAngleRight}
          left={false}
          text="Weiter"
          onClick={onNextClick}
          disabled={
            explore?.selectedZones?.length <= 0 &&
            explore?.disabledZones?.length <= 0 &&
            explore?.requiredZones?.length <= 0
          }
        />
      </ButtonContainer>
    </Sidebar>
  );
};

const Image = styled.img`
  cursor: pointer;
  width: 100%;
  display: block;
  height: auto;
  margin-bottom: 10px;
  border-radius: 5px;
  transition: 0.5s ease;
  &:hover {
    filter: brightness(70%);
  }
`;

export const MapButtonConatiner = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 20px;
`;

export default Info;
