import { useEffect } from "react";
import format from "date-fns/format";

export const checkIfRoadAndZoneSet = (explore) => {
  if (!(explore.roadName && explore.roadId && explore.zoneId)) {
    return false;
  }
  return true;
};

export const setRoad = (
  roadId,
  roadName,
  description,
  location,
  image,
  zones,
  dispatchExplore
) => {
  dispatchExplore({
    type: "setRoad",
    data: {
      roadId: roadId,
      roadName: roadName,
      description: description,
      roadImage: image,
      zones: zones,
      location: location,
    },
  });
};

export const getTitleForType = (pass) => {
  // eslint-disable-next-line default-case
  switch (pass.type) {
    case "ENDLESS":
      return "Dauerkarte";
    case "DAY":
      return "Tageskarte";
    case "THREEDAY":
      return "3-Tageskarte";
    case "WEEK":
      return "Wochenkarte";
    case "MONTH":
      return "Monatskarte";
    case "YEAR":
      return "Jahreskarte";
    case "CUSTOM":
      return pass.name;
    case "CUSTOM_RANGE":
      return (
        pass.title +
        " (" +
        format(new Date(pass.startDate.seconds * 1000), "dd.MM.yyyy") +
        " - " +
        format(new Date(pass.endDate.seconds * 1000), "dd.MM.yyyy") +
        ")"
      );
    case "CUSTOM_DURATION":
      return pass.title + " (" + pass.duration + " Tage)";
  }
};

export function useOutsideAlerter(ref, action) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        action();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
