import React from "react";
import styled from "styled-components";

const Button = ({ text, Icon, left, onClick, disabled = false }) => {
  return (
    <ButtonContainer
      onClick={(event) => (!disabled ? onClick(event) : null)}
      disabled={disabled}
    >
      {Icon && left && <Icon size="18px" style={{ marginRight: "15px" }} />}
      {text}
      {Icon && !left && <Icon size="18px" style={{ marginLeft: "15px" }} />}
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 13px;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 5px;
  background-color: ${(props) => (props.disabled ? "#E3E3E0" : "#165E16")};
  color: white;
  width: auto;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => (props.disabled ? "#E3E3E0" : "#00340E")};
  }
`;

export default Button;
