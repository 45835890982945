import React from "react";
import styled from "styled-components";
import DoneIcon from "@material-ui/icons/Done";
import { useLocation, Link } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Success = () => {
  let query = useQuery();
  return (
    <Wrapper>
      <Done />
      <Headline>Danke für deinen Einkauf!</Headline>
      <p style={{ textAlign: "center", maxWidth: "500px" }}>
        Deine Fahrtberechtigung ist nun in unserem System freigeschaltet!
        <br />
        <br />
        {/*
                Um die Details zu deiner Berechtigung anzuzeigen gehe auf folgende Seite:
                <br />
                <br />
                */}
        Deine Fahrtberechtigungsnummer lautet {query.get("id")}.
        <br />
        <br />
        Falls du eine Frage zu deiner
        Fahrtberechtigung hast, sende uns ein Mail mit deiner
        Fahrtberechtigungsnummer an folgende Adresse:{" "}
        <a href={"mailto:info@mauto.at"}>info@mauto.at</a>
      </p>
      <Link to="/">Zurück zur Übersicht</Link>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Done = styled(DoneIcon)`
  color: #447604;
  font-size: 200px;
`;

const Headline = styled.h3`
  font-weight: 700;
  font-size: 20px;
`;

export default Success;
