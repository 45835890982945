import React, { forwardRef } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import InputContainer from './InputContainer'
import InputWithIcon from './InputWithIcon'

const DateInput = ({ marginTop, title, value, onChange, minDate }) => {
    return (
        <InputContainer title={title} marginTop={marginTop}>
            <DatePicker
                dateFormat="dd.MM.yyyy"
                selected={value}
                onChange={date => onChange(date)}
                minDate={minDate}
                customInput={<InputWithIcon type="text" Icon={FaCalendarAlt} onIconClick={'ON_CLICK'} />}
                style={{ width: "100%" }}
            />
        </InputContainer>
    );
}

export default DateInput;