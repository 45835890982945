import React from "react";

const ExploreStateContext = React.createContext();
const ExploreDispatchContext = React.createContext();

const exploreReducer = (state, action) => {
  switch (action.type) {
    case "setRoad": {
      let explore = {};
      let pass = {};
      explore.roadId = action.data.roadId;
      pass.roadId = action.data.roadId;
      explore.roadName = action.data.roadName;
      explore.description = action.data.description;
      explore.roadImage = action.data.roadImage;
      pass.roadName = action.data.roadName;
      explore.zones = action.data.zones;
      explore.selectedZones = [];
      explore.disabledZones = [];
      explore.requiredZones = [];
      explore.location = action.data.location;
      explore.actual = "INFO";
      return { explore: explore, pass: pass };
    }
    case "setZone": {
      let explore = state.explore;
      let pass = state.pass;
      explore.requiredZones = action.data.requiredZones;
      explore.selectedZones = action.data.selectedZones;
      explore.disabledZones = action.data.disabledZones;
      return { explore: explore, pass: pass };
    }
    case "setInfoToDetail": {
      let explore = state.explore;
      explore.actual = "DETAIL";
      return { explore: explore, pass: state.pass };
    }
    case "setDetailToInfo": {
      let oldExplore = state.explore;
      let explore = {};
      let pass = {};
      explore.roadId = oldExplore.roadId;
      pass.roadId = oldExplore.roadId;
      explore.roadName = oldExplore.roadName;
      explore.description = oldExplore.description;
      explore.roadImage = oldExplore.roadImage;
      pass.roadName = oldExplore.roadName;
      explore.zones = oldExplore.zones;
      explore.selectedZones = oldExplore.selectedZones;
      explore.disabledZones = oldExplore.disabledZones;
      explore.requiredZones = oldExplore.requiredZones;
      explore.location = oldExplore.location;
      explore.actual = "INFO";
      return { explore: explore, pass: pass };
    }
    case "setDetailToCheckout": {
      let explore = state.explore;
      explore.actual = "CHECKOUT";
      return { explore: explore, pass: state.pass };
    }
    case "setCheckoutToDetail": {
      let explore = state.explore;
      explore.actual = "DETAIL";
      return { explore: explore, pass: state.pass };
    }
    case "setDetailPresets": {
      let explore = state.explore;
      explore.detailPresets = action.data;
      return { explore: explore, pass: state.pass };
    }
    case "setPassName": {
      let explore = state.explore;
      explore.passName = action.data;
      return { explore: explore, pass: state.pass };
    }
    case "setPass": {
      return { explore: state.explore, pass: action.data.pass };
    }
    case "setLoading": {
      let explore = state.explore;
      explore.actual = "LOADING";
      return { explore: explore, pass: state.pass };
    }
    case "reset": {
      return { explore: { actual: "SEARCH" }, pass: {} };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const ExploreProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(exploreReducer, {
    explore: { actual: "SEARCH" },
    pass: {},
  });
  return (
    <ExploreStateContext.Provider value={state}>
      <ExploreDispatchContext.Provider value={dispatch}>
        {children}
      </ExploreDispatchContext.Provider>
    </ExploreStateContext.Provider>
  );
};

const useExploreState = () => {
  const context = React.useContext(ExploreStateContext);
  if (context === undefined) {
    throw new Error("useExploreState must be used within a ExploreProvider");
  }
  return context;
};

const useExploreDispatch = () => {
  const context = React.useContext(ExploreDispatchContext);
  if (context === undefined) {
    throw new Error("useExploreDispatch must be used within a ExploreProvider");
  }
  return context;
};

export { ExploreProvider, useExploreState, useExploreDispatch };
