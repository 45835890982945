import React from "react";
import styled from "styled-components";
import {
  useExploreDispatch,
  useExploreState,
} from "../../../context/ExploreContext";
import { setRoad } from "../../../functions/helpers";
import { FaAngleRight } from "react-icons/fa";
import { gql, useLazyQuery } from "@apollo/client";

const GET_ZONES = gql`
  query Query($roadId: Int!) {
    zonesByRoadId(roadId: $roadId) {
      coordinates {
        latitude
        longitude
      }
      id
      name
      required
      dependsOn {
        id
      }
      passRates {
        passRate {
          id
        }
        price
      }
    }
  }
`;

function SearchItem({ hit }) {
  const { explore } = useExploreState();

  const [getZones] = useLazyQuery(GET_ZONES);

  const dispatchExplore = useExploreDispatch();

  const onClick = () => {
    if (hit.id === explore.roadId) {
      dispatchExplore({ type: "reset" });
    } else {
      getZones({
        variables: { roadId: hit.id },
        onCompleted: (data) => {
          setRoad(
            hit.id,
            hit.name,
            hit.description,
            hit.location,
            hit.image,
            data.zonesByRoadId,
            dispatchExplore
          );
          const requiredZones = [];
          for (const zone of data.zonesByRoadId) {
            if (zone.required) {
              requiredZones.push(zone.id);
            }
          }
          console.log(data.zonesByRoadId);
          dispatchExplore({
            type: "setZone",
            data: {
              selectedZones:
                requiredZones.length > 0 ? [] : [data.zonesByRoadId[0].id],
              disabledZones: [],
              requiredZones: requiredZones,
            },
          });
        },
      });
    }
  };

  return (
    <Container onClick={() => onClick()}>
      <Text>{hit.name}</Text>
      <FaAngleRight size="22px" color="black" />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #b2c3cc;
  border-bottom: 1px solid #b2c3cc;
  cursor: pointer;
  &:hover {
    background-color: #f8f8f8;
  }
`;

const Text = styled.p`
  margin-top: 15px;
  margin-bottom: 14px;
  font-weight: 600;
  font-size: 16px;
  color: black;
`;

export default SearchItem;
