import React from 'react';
import styled from 'styled-components';

const InputContainer = ({ marginTop, children, title, border = true, rightNotRounded = false, leftNotRounded = false, bottomNotRounded = false }) => {
    return (
        <Wrapper marginTop={marginTop}>
            {title && <Title>{title}</Title>}
            <Container border={border} rightNotRounded={rightNotRounded} leftNotRounded={leftNotRounded} bottomNotRounded={bottomNotRounded}>
                {children}
            </Container>
        </Wrapper>
    );
}

const Container = styled.div`
    border-top-left-radius: ${props => props.leftNotRounded ? "" : "5px"};
    border-bottom-left-radius: ${props => props.leftNotRounded || props.bottomNotRounded ? "" : "5px"};
    border-top-right-radius: ${props => props.rightNotRounded ? "" : "5px"};
    border-bottom-right-radius: ${props => props.rightNotRounded || props.bottomNotRounded ? "" : "5px"};
    border: ${props => props.border ? "1px solid #B2C3CC" : ""};
`

const Wrapper = styled.div`
    margin-top: ${props => props.marginTop};
`

const Title = styled.h6`
    margin: 0px;
    margin-bottom: 4px;
    font-size: 13px;
    font-weight: 600;
`

export default InputContainer;