import React from "react";
import styled from "styled-components";
import LogoSrc from "../../assets/LogoColor.svg";
const Footer = () => {
  return (
    <Wrapper>
      <Left>
        <a href="/">
          <Logo src={LogoSrc} />
        </a>

        <Caption>Die digitale Wegverwaltungssoftware</Caption>
        <Powered>
          Powered by{" "}
          <ZewasLink target="blank" href="https://zewas.at">
            Zewas
          </ZewasLink>
        </Powered>
      </Left>
      <Right>
        <Link href="/">Home</Link>

        <Link href="/imprint">Impressum</Link>

        <Link href="/privacy">Datenschutz</Link>
        <Link href="/agb">AGB</Link>
      </Right>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 10vh;
  margin-bottom: 2vh;

  width: 100%;
  display: flex;
  @media (max-width: 700px) {
    flex-flow: column;
  }
`;

const Left = styled.div`
  width: 50%;
  display: flex;

  justify-content: flex-start;
  flex-flow: column;
  @media (max-width: 700px) {
    width: 100%;
    align-items: center;
  }
`;
const Right = styled.div`
  width: 50%;
  gap: 1vw;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  @media (max-width: 700px) {
    gap: 2vw;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
  }
`;
const Logo = styled.img`
  height: 32px;
  margin-bottom: 5px;
`;
const Link = styled.a`
  color: #165e16;
  font-size: 14px;
  cursor: pointer;
`;
const ZewasLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;
const Caption = styled.span`
  font-size: 0.875rem;
`;
const Powered = styled.span`
  margin-top: 1vh;
  font-size: 0.875rem;
`;

export default Footer;
